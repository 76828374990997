import React from 'react';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import Layout from '../Layout';
import {
	Metatags,
	SocialMedia,
	// SubscribeForm
	// Carbon,
	// CarbonNative
} from '../Other';
import avatar from '../../static/images/avatar.jpg';

function BlogPost(props) {
	const post = props.data.markdownRemark;
	const url = props.data.site.siteMetadata.siteUrl;
	const { slug } = post.fields;
	const { title, description, tags } = post.frontmatter;
	const pathname = props.location.pathname;
	const { next, prev } = props.pageContext;
	const metaTagProps = {
		title: `Florin Pop - ${title}`,
		description,
		url,
		pathname,
		thumbnail: avatar
	};
	const socialMediaUrl = `${url}${slug}`;

	return (
		<Layout>
			<Metatags {...metaTagProps} />
			<section className='col-sm-8 col-sm-offset-2 col-xs-12'>
				<div className='blog'>
					{/* <Carbon carbonUrl='//cdn.carbonads.com/carbon.js?serve=CK7ICKJI&placement=wwwflorin-popcom' />
					<CarbonNative /> */}
					<h1>{title}</h1>
					<div
						className='blog-content'
						dangerouslySetInnerHTML={{ __html: post.html }}
					/>
				</div>
				<i className='i-tags'>
					Tagged with{' '}
					{tags.length > 0 &&
						tags.map((tag, i) => (
							<span key={i}>
								<Link
									to={`/tags/${kebabCase(tag)}`}
									key={i}
									className='underlined'>
									{tag}
								</Link>
								{i !== tags.length - 1 && ', '}
							</span>
						))}
					<button className='btn-tag'>
						<Link to='/tags'>View all tags</Link>
					</button>
				</i>
				<div className='navigation-buttons'>
					{prev && (
						<Link to={prev.fields.slug} className='prev-btn'>
							<button>{'<'} Prev Article</button>
						</Link>
					)}
					{next && (
						<Link to={next.fields.slug} className='next-btn'>
							<button>Next Article {'>'}</button>
						</Link>
					)}
				</div>
				<SocialMedia url={socialMediaUrl} title={title} />
				{/* <SubscribeForm /> */}
			</section>
		</Layout>
	);
}

export default BlogPost;

export const query = graphql`
	query PostQuery($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			fields {
				slug
			}

			frontmatter {
				title
				description
				tags
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
